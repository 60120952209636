import axios from 'axios';

const service = axios.create({
  baseURL: '',
  timeout: 20000,
});

/* 过滤请求 */
service.interceptors.request.use((config) => config);

/* 过滤响应 */
service.interceptors.response.use((result) => result.data, (err) => Promise.reject(err));

export default service;
